var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('FilterList', {
    attrs: {
      "filter": _vm.filter,
      "status": _vm.status
    },
    on: {
      "onCreate": function onCreate() {
        return _vm.supportReqId = null;
      },
      "refresh": _vm.fetchItem,
      "update-filter": function updateFilter(val) {
        return _vm.filter = val;
      }
    }
  }), _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm",
      "no-fade": "",
      "variant": "transparent",
      "opacity": 0.5
    }
  }, [_c('b-table', {
    ref: "refDataTable",
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "70vh"
    },
    attrs: {
      "bordered": "",
      "no-border-collapse": "",
      "sticky-header": "",
      "responsive": "",
      "show-empty": "",
      "primary-key": "id",
      "table-class": "table-resell-ticket",
      "items": _vm.items,
      "fields": _vm.columns,
      "empty-text": _vm.$t('noRecordFund'),
      "sort-by": _vm.filter.sortBy,
      "sort-desc": _vm.filter.isSortDirDesc
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        return _vm.$set(_vm.filter, "sortBy", $event);
      },
      "update:sort-by": function updateSortBy($event) {
        return _vm.$set(_vm.filter, "sortBy", $event);
      },
      "update:sortDesc": function updateSortDesc($event) {
        return _vm.$set(_vm.filter, "isSortDirDesc", $event);
      },
      "update:sort-desc": function updateSortDesc($event) {
        return _vm.$set(_vm.filter, "isSortDirDesc", $event);
      },
      "row-clicked": function rowClicked(val) {
        if (_vm.clickedId != null && _vm.clickedId == val.id) { _vm.clickedId = null; }else { _vm.clickedId = val.id; }
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('div', {
            key: column.label,
            staticClass: "text-dark text-nowrap text-center"
          }, [_vm._v(" " + _vm._s(_vm.$t("supportReq.columns.".concat(data.label))) + " ")])];
        }
      };
    }), _vm._l(_vm.columns, function (column) {
      return {
        key: "cell(".concat(column.key, ")"),
        fn: function fn(_ref) {
          var item = _ref.item;
          return [_c('div', {
            key: column.key,
            staticClass: "text-nowrap",
            class: {
              'text-right': typeof item[column.key] === 'number'
            }
          }, [_vm._v(" " + _vm._s(item[column.key]) + " ")])];
        }
      };
    }), {
      key: "cell(stt)",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(data.index + 1))])];
      }
    }, {
      key: "cell(createdAt)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.createdAt).dateTime) + " ")])];
      }
    }, {
      key: "cell(status)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('span', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.$t("supportReq.status.".concat(item.status))) + " ")])];
      }
    }, {
      key: "cell(content)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.content ? _c('span', {
          class: _vm.clickedId != item.id ? 'two-line-ellipsis' : ''
        }, [_vm._v(" " + _vm._s(item.content) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell(note)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [item.note ? _c('span', {
          class: _vm.clickedId != item.id ? 'two-line-ellipsis' : ''
        }, [_vm._v(" " + _vm._s(item.note) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell(flight)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [item.bookingInfo ? _c('div', _vm._l(item.bookingInfo.itineraries, function (trip, index) {
          return _c('div', {
            key: index,
            staticClass: "text-nowrap text-body-3"
          }, _vm._l(trip, function (segment, indexSegment) {
            return _c('div', {
              key: indexSegment,
              staticClass: "d-flex flex-nowrap my-50"
            }, [segment.airline ? _c('IAmLogoAirline', {
              attrs: {
                "airline": segment.operating === 'BL' ? segment.operating : segment.airline,
                "size": "xs",
                "rounded": "",
                "custom-class": "mr-50"
              }
            }) : _vm._e(), _vm._v(" " + _vm._s(_vm.convertShortTrip(segment)) + " ")], 1);
          }), 0);
        }), 0) : _vm._e()];
      }
    }, {
      key: "cell(createdBy)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "w-100 h-100 d-flex-center flex-column",
          attrs: {
            "id": "booker-".concat(data.item.createdBy.id, "-").concat(data.item.createdAt)
          }
        }, [_c('div', {
          staticClass: "text-info font-weight-bold text-body-3 text-nowrap"
        }, [_vm._v(" " + _vm._s(data.item.createdBy.username.toUpperCase()) + " ")]), _c('div', {
          staticClass: "text-dark font-weight-bold text-body-3 text-nowrap"
        }, [_vm._v(" (" + _vm._s(_vm.isRoleF1 && data.item.agency.parentAgency && data.item.agency.parentAgency.id !== 1000000 ? data.item.agency.parentAgency.agencyCode.toUpperCase() : data.item.agency.agencyCode.toUpperCase()) + ") ")]), _c('b-tooltip', {
          attrs: {
            "target": "booker-".concat(data.item.createdBy.id, "-").concat(data.item.createdAt),
            "triggers": "hover",
            "placement": "top",
            "boundary": "viewport",
            "variant": "dark"
          }
        }, [_c('BRow', [_c('BCol', {
          staticClass: "text-nowrap",
          attrs: {
            "cols": "4"
          }
        }, [_vm._v(" Tên tài khoản: ")]), _c('BCol', {
          attrs: {
            "cols": "8"
          }
        }, [_c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(" " + _vm._s(data.item.createdBy.lastName) + " " + _vm._s(data.item.createdBy.firstName) + " (" + _vm._s(data.item.createdBy.type) + ") ")])]), _c('BCol', {
          staticClass: "text-nowrap",
          attrs: {
            "cols": "4"
          }
        }, [_vm._v(" Mã tài khoản: ")]), _c('BCol', {
          attrs: {
            "cols": "8"
          }
        }, [_c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(data.item.createdBy.employeeCode.toUpperCase()))])]), _c('BCol', {
          staticClass: "text-nowrap",
          attrs: {
            "cols": "4"
          }
        }, [_vm._v(" Tên đại lý: ")]), _c('BCol', {
          attrs: {
            "cols": "8"
          }
        }, [_c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(data.item.agency.agencyName))])]), _c('BCol', {
          staticClass: "text-nowrap",
          attrs: {
            "cols": "4"
          }
        }, [_vm._v(" Mã đại lý: ")]), _c('BCol', {
          attrs: {
            "cols": "8"
          }
        }, [_c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(data.item.agency.agencyCode.toUpperCase()))])]), _vm.isRoleF1 && data.item.agency.parentAgency && data.item.agency.parentAgency.id !== 1000000 ? _c('BCol', {
          staticClass: "text-nowrap",
          attrs: {
            "cols": "4"
          }
        }, [_vm._v(" Tên ĐL chủ quản: ")]) : _vm._e(), _vm.isRoleF1 && data.item.agency.parentAgency && data.item.agency.parentAgency.id !== 1000000 ? _c('BCol', {
          attrs: {
            "cols": "8"
          }
        }, [_c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(data.item.agency.parentAgency.agencyName))])]) : _vm._e(), _vm.isRoleF1 && data.item.agency.parentAgency && data.item.agency.parentAgency.id !== 1000000 ? _c('BCol', {
          staticClass: "text-nowrap",
          attrs: {
            "cols": "4"
          }
        }, [_vm._v(" Mã ĐL chủ quản: ")]) : _vm._e(), _vm.isRoleF1 && data.item.agency.parentAgency && data.item.agency.parentAgency.id !== 1000000 ? _c('BCol', {
          attrs: {
            "cols": "8"
          }
        }, [_c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(data.item.agency.parentAgency.agencyCode.toUpperCase()))])]) : _vm._e()], 1)], 1)], 1)];
      }
    }, {
      key: "cell(create)",
      fn: function fn(_ref7) {
        var _item$agency, _item$agency2;
        var item = _ref7.item;
        return [item.agency ? _c('span', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s((_item$agency = item.agency) === null || _item$agency === void 0 ? void 0 : _item$agency.agencyName) + " (" + _vm._s((_item$agency2 = item.agency) === null || _item$agency2 === void 0 ? void 0 : _item$agency2.agencyCode) + ") ")]) : _vm._e()];
      }
    }, {
      key: "cell(airline)",
      fn: function fn(_ref8) {
        var _item$airline, _item$airline2;
        var item = _ref8.item;
        return [item.airline ? _c('span', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s((_item$airline = item.airline) === null || _item$airline === void 0 ? void 0 : _item$airline.name) + " (" + _vm._s((_item$airline2 = item.airline) === null || _item$airline2 === void 0 ? void 0 : _item$airline2.code) + ") ")]) : _vm._e()];
      }
    }, {
      key: "cell(pnr)",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [item.bookingInfo ? _c('b-link', {
          staticClass: "font-weight-bold",
          attrs: {
            "to": {
              name: 'apps-reservations-modify',
              params: {
                id: item.bookingInfo.id
              }
            },
            "target": "_blank"
          }
        }, [_vm._v(" " + _vm._s(item.pnr) + " ")]) : _c('span', [_vm._v(_vm._s(item.pnr))])];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('div', {
          staticClass: "d-flex gap-1",
          style: _vm.isMobileView ? "overflow: scroll; width:60px" : ""
        }, [item.status == 'PENDING' ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-dark.window",
            value: _vm.$t('supportReq.btn.edit'),
            expression: "$t('supportReq.btn.edit')",
            modifiers: {
              "hover": true,
              "v-dark": true,
              "window": true
            }
          }, {
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "py-50 px-75 rounded",
          attrs: {
            "id": "btnEdit",
            "variant": "flat-primary",
            "size": "md"
          },
          on: {
            "click": function click() {
              _vm.supportReqId = item.id;
              _vm.$bvModal.show('create-support-modal');
            }
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "editOutline",
            "size": "18"
          }
        })], 1) : _vm._e(), _vm.isRoleF1 && (item.status === 'PENDING' || item.status === 'PROCESSING') ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-dark.window",
            value: _vm.$t('supportReq.btn.reject'),
            expression: "$t('supportReq.btn.reject')",
            modifiers: {
              "hover": true,
              "v-dark": true,
              "window": true
            }
          }, {
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "py-50 px-75 rounded",
          attrs: {
            "id": "btnReject",
            "variant": "flat-danger",
            "size": "md"
          },
          on: {
            "click": function click($event) {
              return _vm.handleChangeStatus(item, 'REJECTED');
            }
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "rejected",
            "size": "18"
          }
        })], 1) : _vm._e(), _vm.isRoleF1 && (item.status === 'PENDING' || item.status === 'PROCESSING') ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-dark.window",
            value: _vm.$t('supportReq.status.SELF_HANDLED'),
            expression: "$t('supportReq.status.SELF_HANDLED')",
            modifiers: {
              "hover": true,
              "v-dark": true,
              "window": true
            }
          }, {
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "py-50 px-75 rounded",
          attrs: {
            "id": "btnSelfHandle",
            "variant": "flat-primary",
            "size": "md"
          },
          on: {
            "click": function click($event) {
              return _vm.handleChangeStatus(item, 'SELF_HANDLED');
            }
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "userOutline",
            "size": "18"
          }
        })], 1) : _vm._e(), (item.status === 'PENDING' || item.status === 'PROCESSING') && _vm.isRoleF1 ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-dark.window",
            value: _vm.$t('supportReq.status.COMPLETED'),
            expression: "$t('supportReq.status.COMPLETED')",
            modifiers: {
              "hover": true,
              "v-dark": true,
              "window": true
            }
          }, {
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "py-50 px-75 rounded",
          attrs: {
            "id": "btnComplete",
            "variant": "flat-success",
            "size": "md"
          },
          on: {
            "click": function click($event) {
              return _vm.handleChangeStatus(item, 'COMPLETED');
            }
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "checked",
            "size": "18"
          }
        })], 1) : _vm._e(), item.status === 'PENDING' && _vm.isRoleF1 ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-dark.window",
            value: _vm.$t('supportReq.status.PROCESSING'),
            expression: "$t('supportReq.status.PROCESSING')",
            modifiers: {
              "hover": true,
              "v-dark": true,
              "window": true
            }
          }, {
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "py-50 px-75 rounded",
          attrs: {
            "id": "btnProcessing",
            "variant": "flat-info",
            "size": "md"
          },
          on: {
            "click": function click($event) {
              return _vm.handleChangeStatus(item, 'PROCESSING');
            }
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "handle",
            "size": "18"
          }
        })], 1) : _vm._e(), item.status === 'PENDING' && _vm.isRoleF2 ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-dark.window",
            value: _vm.$t('supportReq.btn.cancel'),
            expression: "$t('supportReq.btn.cancel')",
            modifiers: {
              "hover": true,
              "v-dark": true,
              "window": true
            }
          }, {
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "py-50 px-75 rounded",
          attrs: {
            "id": "canceled",
            "variant": "flat-danger",
            "size": "md"
          },
          on: {
            "click": function click($event) {
              return _vm.handleChangeStatus(item, 'CANCELED');
            }
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "closeOutline",
            "size": "18"
          }
        })], 1) : _vm._e(), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-dark.window",
            value: _vm.$t('resellTicket.btn.logsDetail'),
            expression: "$t('resellTicket.btn.logsDetail')",
            modifiers: {
              "hover": true,
              "v-dark": true,
              "window": true
            }
          }, {
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "py-50 px-75 rounded",
          attrs: {
            "id": "btnDetail",
            "variant": "flat-info",
            "size": "md"
          },
          on: {
            "click": function click($event) {
              return _vm.detailLogsHandle(item.id);
            }
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "receipt",
            "size": "18"
          }
        })], 1)], 1)];
      }
    }], null, true)
  })], 1), _c('b-container', {
    staticClass: "bg-white py-1",
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('span', {
    staticClass: "text-muted"
  }, [_vm._v(" " + _vm._s(_vm.dataMeta.from) + " " + _vm._s(_vm.$t('paginationText.to')) + " " + _vm._s(_vm.dataMeta.to) + " " + _vm._s(_vm.$t('paginationText.outOf')) + " " + _vm._s(_vm.dataMeta.of) + " " + _vm._s(_vm.$t('paginationText.items')) + " ")])]), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('b-pagination', {
    staticClass: "mb-0 mt-1 mt-sm-0",
    attrs: {
      "total-rows": _vm.total,
      "per-page": _vm.filter.size,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filter.page,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "page", $$v);
      },
      expression: "filter.page"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('v-select', {
    staticClass: "per-page-selector ",
    attrs: {
      "options": _vm.sizePerPageLgOptions,
      "clearable": false
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filter.size,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "size", $$v);
      },
      expression: "filter.size"
    }
  })], 1)], 1)], 1)], 1), _c('CreateModal', {
    attrs: {
      "id": _vm.supportReqId
    },
    on: {
      "update:id": function updateId($event) {
        _vm.supportReqId = $event;
      },
      "refresh": function refresh($event) {
        return _vm.fetchItem();
      }
    }
  }), _c('LogModal', {
    attrs: {
      "logs": _vm.supportReqLogs
    },
    on: {
      "update:logs": function updateLogs($event) {
        _vm.supportReqLogs = $event;
      }
    }
  }), _c('BModal', {
    attrs: {
      "id": "modal-actions",
      "title": _vm.$t('resellTicket.btn.logsDetail'),
      "title-class": "text-airline font-weight-bolder ".concat(_vm.isMobileView ? 'font-medium-2' : 'font-medium-4'),
      "body-class": "p-1",
      "size": "lg",
      "centered": ""
    },
    on: {
      "ok": _vm.updateHandle
    }
  }, [_vm.updateStatus ? _c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('modal.changeStatusWithText', {
    text: _vm.$t("supportReq.status.".concat(_vm.updateStatus))
  })) + " ")]) : _vm._e(), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "content"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('supportReq.form.content')) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "content",
      "placeholder": _vm.$t('supportReq.form.content'),
      "name": _vm.$t('supportReq.form.content'),
      "disabled": _vm.isRoleF1,
      "rows": "5"
    },
    model: {
      value: _vm.updateItem.content,
      callback: function callback($$v) {
        _vm.$set(_vm.updateItem, "content", $$v);
      },
      expression: "updateItem.content"
    }
  })], 1)], 1), _vm.isRoleF1 ? _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "note"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('supportReq.form.note')) + " ")];
      },
      proxy: true
    }], null, false, 685964136)
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "note",
      "placeholder": _vm.$t('supportReq.form.note'),
      "name": _vm.$t('supportReq.form.note'),
      "rows": "5"
    },
    model: {
      value: _vm.updateItem.note,
      callback: function callback($$v) {
        _vm.$set(_vm.updateItem, "note", $$v);
      },
      expression: "updateItem.note"
    }
  })], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }